import { Request } from '@prodelio/hooks/api/Request.class';
import { apiWrapper } from '@prodelio/config/api/api';
import { toastError } from '@prodelio/config/toast/toast';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';

export const createProject = (set: any, get: any, request: Request) => {
  const createdAt = new Date().toISOString();
  const updatedAt = createdAt;

  apiWrapper(request).catch((e: any) => {
    toastError('Error when creating the project.', e.error);
    const syncProjects = get().syncProjects;
    set(() => ({
      syncProjects: [
        ...syncProjects,
        {
          ...request.body,
          createdAt,
          updatedAt,
          syncStatus: SyncStatus.CREATED,
        },
      ],
    }));
  });

  const createdProject = {
    ...request.body,
    createdAt,
    updatedAt,
  };
  set(({ projects }: any) => ({
    projects: [...projects, createdProject],
  }));
};

export type CreateProjectType = (request: Request) => void;
